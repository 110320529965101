@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kingthings Trypewriter 2";
  src: url("../assets/fonts/kingthings_trypewriter_2.ttf");

  font-weight: 400;
}

.ff-king {
  font-family: "Kingthings Trypewriter 2";
}

.ff-zilla {
  font-family: 'Zilla Slab', serif;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'zilla Slab', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.striped {
  background: repeating-linear-gradient(45deg,
      #f8f8f8,
      #f8f8f8 10px,
      #fff 10px,
      #fff 20px);
}

.element {
  border-image-source: url(../assets/border.svg);
  border-image-slice: 20;
  border-image-width: 20;
  border-image-outset: 1;
  border-image-repeat: round;
}